<template>
  <v-data-table
    v-model="selectedRuns"
    class="custom-table table-fixed font-inter mt-6"
    hide-default-footer
    show-select
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    :headers="headers"
    :items="itemsPerView"
    :item-key="itemKey"
  >
    <template v-slot:[`headers.name`]="{ header }">
      <span class="header_text">{{ header.text }}</span>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <v-tooltip
        top
        left
        max-width="485px"
        :disabled="item.name.length < 61"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="custom-attribute text-truncate font-weight-bold"
            v-bind="attrs"
            v-on="on"
          >
            {{ item.name }}
          </div>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.testcases`]="{ item }">
      <td class="d-flex align-center">
        <div class="text-start">
          <div class="text-subtitle-3">
            {{ item.customFields.caseCount }} {{ $t('plans.testRuns.testCases') }}
          </div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.testruns`]="{ item }">
      <td class="d-flex align-center">
        <div class="text-start">
          <div class="text-subtitle-2 font-weight-bold">
            {{ item.testruns }} {{ $t('plans.testRuns.testRuns') }}
          </div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.configuration`]="{ item }">
      <div class="d-flex flex-row align-center justify-end">
        <button
          v-if="!item.configurations || item.configurations.length <= 0"
          class="btn-add-configuration"
          @click="isOpenAddConfigurations = true"
        >
          <v-icon>mdi-plus</v-icon> {{ $t('add') }}
        </button>
        <v-menu
          v-else
          left
          offset-y
          class="font-inter"
        >
          <template v-slot:activator="{ on }">
            <button
              class="btn-configuration font-inter text-none"
              v-on="on"
            >
              {{ item.configurations[0].title }} <v-icon color="#344054">
                mdi-chevron-down
              </v-icon>
            </button>
          </template>
          <v-list
            dense
            class="configurations-menu text-left font-inter"
          >
            <v-subheader class="font-inter pt-2 px-4">
              {{ $t('configurations') }}
            </v-subheader>
            <v-list-item
              v-for="(group, index) of item.configurations"
              :key="index"
              class="pt-2 px-4 d-flex align-center"
            >
              <span>{{ group.title }}</span>
              <button
                class="btn-edit d-flex ml-1"
                @click="$emit('edit', item)"
              >
                <iconEdit />
              </button>
              <button
                class="btn-delete d-flex ml-1"
                @click="$emit('delete', item)"
              >
                <iconDelete />
              </button>
            </v-list-item>
            <v-list-item>
              <button
                class="btn-add-configuration"
                @click="isOpenAddConfigurations = true"
              >
                <v-icon>mdi-plus</v-icon> {{ $t('add') }}
              </button>
            </v-list-item>
          </v-list>
        </v-menu>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <v-btn icon>
          <v-icon color="gray-500">
            mdi-minus-box-outline
          </v-icon>
        </v-btn>
        <add-configuration-dialog
          :open="isOpenAddConfigurations"
          @add="handleAddConfigurations(item, $event)"
          @close="isOpenAddConfigurations = false"
        />
      </div>
    </template>
    <template v-slot:[`item.priority`]="{ item }">
      <span :class="getColor(item.priority)">{{ item.priority }}</span>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <span :class="getColor(item.status)">{{ item.status }}</span>
    </template>
    <template v-slot:[`item.milestone`]="{ item }">
      <span :class="getColor(item.customFields.milestone)">{{ item.customFields.milestone }}</span>
    </template>
    <template v-slot:[`item.tags`]="{ item }">
      <span :class="getColor(item.customFields.tags)">{{ item.customFields.tags }}</span>
    </template>
    <template v-slot:[`item.creationdate`]="{ item }">
      <span class="">{{ item.createdAt }}</span>
    </template>
    <template v-slot:[`item.progress`]="{ item }">
      <div class="d-flex flex-row align-center justify-space-between">
        <v-progress-linear
          rounded
          color="#0c2ff3"
          background-color="#F2F4F7"
          :height="8"
          :value="item.customFields.progress"
        />
        <span class="font-weight-regular ml-3">{{ item.customFields.progress }}%</span>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import AddConfigurationDialog from '@/views/Tests/Plans/Components/Configuration/Index';

import iconEdit from '@/assets/svg/edit-16x16.svg';
import iconDelete from '@/assets/svg/delete-16x16.svg';

export default {
  name: 'TestRunsList',

  components: {
    AddConfigurationDialog,
    iconEdit,
    iconDelete,
  },
  props: {
    tab: {
      type: String,
      default: 'all',
    },
    data: {
      type: Array,
    },
    selectedRuns: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    filteredItems: {
      type: Array,
    },
    rowClass: {
      type: Function,
    },
  },
  data() {
    return {
      itemKey: 'name',
      selected: this.selectedRuns,
      isAllTestRunChecked: false,
      isSelectedTestRunChecked: false,
      isOpenAddConfigurations: false,
    };
  },
  computed: {
    itemsPerView() {
      if (this.tab === 'all') {
        return this.data
      } else {
        return this.selectedRuns
      }
    },
  },
  watch: {
    tab(newValue) {
      this.toggleTestRuns();
    },
    selectedRuns(newValue) {
      this.$emit('selectTestRun', this.selectedRuns)
    }
  },
  methods: {
    getColor(priority) {
      switch(priority){
        case "High":
        case "Failed":
          return "font-weight-bold red--text text--lighten-1"
        case "Medium":
        case "Rerun":
          return "font-weight-bold orange--text text--lighten-1"
        case "Low":
        case "Active":
          return "font-weight-bold green--text text--lighten-1"
        case "Passed":
          return "font-weight-bold deep-purple--text text--lighten-1"
        case "In progress":
          return "font-weight-bold grey--text text--lighten-1"
      }
    },
    toggleTestRuns() {
      const isSelected = this.tab === 'all' ? this.isAllTestRunChecked : this.isSelectedTestRunChecked;
      this.data.forEach((run) => {
        const condition = this.tab === 'all' ? !run.selected : run.selected;
        if (condition) {
          this.$set(run, 'toBeSelected', isSelected);
        }
      });
    },
    toBeSelectedHandler() {
      const filteredRuns =
        this.tab === 'all' ? this.data.filter((run) => !run.selected) : this.data.filter((run) => run.selected);
      const allTrue = filteredRuns.every((run) => run.toBeSelected);

      if (this.tab === 'all') {
        this.isAllTestRunChecked = allTrue;
      } else {
        this.isSelectedTestRunChecked = allTrue;
      }
    },
    handleAddConfigurations(item, value) {
      item.configurations = value;
      this.isOpenAddConfigurations = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-add-configuration {
  padding: 0;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #667085;

  display: inline-flex;
  align-items: center;
  gap: 8px;

  &::before {
    display: none;
  }

  .v-icon {
    color: #667085;
  }
}

.btn-add-configuration:hover {
  color: #0c2ff3;

  .v-icon {
    color: #0c2ff3;
  }
}

.configurations-menu {
  .v-subheader {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0c111d;
  }

  .btn-edit {
    color: #667085;
  }

  .btn-delete {
    color: #f4284e;
  }

  .btn-add-configuration {
    color: #0c2ff3;
  }
}
</style>